
.Details__accomodations {}
.Details__travel {}
.Details__schedule {}
.Details__brunch {}

.Details__parking {
  height: auto;
  max-width: 375px;
  margin-top: 20px;
}

.Details__brunch .Page__section__details,
.Details__faqs .Page__section__details {
  align-items: flex-start;
  text-align: left;
}

.Details__faqs .Page__section__details p {
  margin-bottom: 1.5em;
}

.Details__schedule__title,
.Details__brunch__title,
.Details__faq__question {
  font-weight: bold;
}

@media (max-width: 1023px) {
  .Details__schedule__title,
  .Details__brunch__title,
  .Details__faq__question {
    display: block;
  }

  .Details__faq__doodle {
    display: none;
  }
}

@media (min-width: 1024px) { 
  .Details__schedule__title,
  .Details__brunch__title,
  .Details__faq__question {
    display: inline-block;
  }

  .Details__parking {
    align-self: center;
    margin-left: 30px;
    margin-top: 1.8em;
  }

  .Details__faq__doodle {
    display: block;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 350px;
    width: 300px;
  }
}
