.Desk {
  background-image: url("../../assets/sketches/spencer-plus-erica-graffiti.png"), url("../../assets/desk_bg.jpg");
  background-position: 10% 98%, top left;
  background-repeat: no-repeat, repeat;
  background-size: 200px, auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 40px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    .Desk {
      background-image: url("../../assets/sketches/spencer-plus-erica-graffiti-2x.png"), url("../../assets/desk_bg.jpg");
    }
}

@media (max-width: 539px) {
  .Desk {
    padding-top: 20px;
  }
}
