
.Card {
  aspect-ratio: var(--card-aspect-ratio);
  background: 
    linear-gradient(#ecedf4 0px, #ecedf4 calc(2em - 1px), #e9bccd 2em, #e9bccd calc(2em + 1px), transparent calc(2em + 2px)), 
    repeating-linear-gradient(to bottom, #ecedf4 0 calc(1em - 2px), #cfe3e4 calc(1em - 2px) 1em);
  padding: 0 1em;
  transform: var(--rotation);
  box-shadow: 0 0 8px -3px rgb(50 50 50 / 30%);
  font-size: 1em;
  line-height: 1em;
  z-index: 1;
  color: var(--graphite);
  flex: 0 0 auto;
  width: 100%;
}

.Card__heading {
 margin: 1em 0 1px;
}

.Card p {
  margin: 24px 0;
}

.Card .Card__note.erica {
  color: green;
}
.Card .Card__note.spencer {
  color: blue;
}

@media (min-width: 614px) and (max-width: 1023px) {
  @supports not (aspect-ratio: 5 / 3) {
    .Card {
      height: 396px;
    }
  }
}

@media (min-width: 1024px) {

  .Card {
    width: var(--card-width);
    position: absolute;
    top: 0;
    left: 0;
  }
  .Card--animate-forwards {
    animation: 800ms ease-in-out 0s 1 reverse backwards cardflip;
  }

  .Card--animate-behind {
    animation: 800ms ease-in-out 0s 1 normal forwards cardflip;
  }

  .Card--back {
    z-index: 0;
  }
}

@keyframes cardflip {
  0% {
    transform: translate(0%, 0%) var(--rotation);
    z-index: 1;
  }
  50% {
    transform: translate(10%, -112%) rotate(8deg);
    z-index: 1;
  }
  51% {
    transform: translate(10%, -112%) rotate(8deg);
    z-index: 0;
  }
  100% {
    transform: translate(0%, 0%) var(--rotation);
    z-index: 0;
  }
}