@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Hi+Melody&family=Indie+Flower&family=Reenie+Beanie&display=swap');

@font-face {
  font-family: 'Sketch Toronto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Sketch-Toronto.otf') format('truetype');
}

* {
  box-sizing: border-box;
}

:root {
  --horizontal-padding-page: 80px;
  --horizontal-padding-medium: 20px;
  --color-paper: #ecedf4;
  --color-line-blue: #cfe3e4;
  --color-line-pink: #e9bccd;
  --graphite: rgb(50 50 60 / 90%);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--graphite);
  font-family: 'Hi Melody';
  font-size: 24px;
  margin: 0;
}


h1,h2,h3 {
  font-family: 'Sketch Toronto';
  font-weight: normal;
  color: black;
}

h1 {
  font-size: 1.6em;
  margin: 0;
}

h2,h3 {
  font-size: 1em;
  margin: 0; 
}

p {
  margin: 0;
}

.spencer {
  font-family: 'Indie Flower';
  font-size: 0.8em;
  text-transform: uppercase;
}
.erica {
  font-family: 'Reenie Beanie';
}
