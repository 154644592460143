.Cards {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.Cards__stack {
  --card-width: 660px;
  --card-aspect-ratio: 5 / 3;

  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  padding-top: 10px;
  position: relative;
}


@media (min-width: 540px) {
  .Cards {
    align-items: center;
  }
  .Cards__stack {
    width: var(--card-width);
    overflow: visible;
    padding-top: 0;
    z-index: 20;
  }
}

@media (min-width: 1024px) {
  .Cards__stack {
    aspect-ratio: var(--card-aspect-ratio);
  }
}

.Cards__stack>*:nth-child(1n) {
  --rotation: rotate(2deg);
}
.Cards__stack>*:nth-child(2n) {
  --rotation: rotate(-1deg);
}
.Cards__stack>*:nth-child(3n) {
  --rotation: rotate(1.5deg);
}
.Cards__stack>*:nth-child(4n) {
  --rotation: rotate(3deg);
}
.Cards__stack>*:nth-child(5n) {
  --rotation: rotate(-2deg);
}
.Cards__stack>*:nth-child(6n) {
  --rotation: rotate(0deg);
}
.Cards__stack>*:nth-child(7n) {
  --rotation: rotate(1deg);
}
.Cards__stack>*:nth-child(8n) {
  --rotation: rotate(0.5deg);
}