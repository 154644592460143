.Polaroid {
  --polaroid-size: 250px;
  --polaroid-padding: 25px;
  aspect-ratio: 88 / 107;
  background: radial-gradient(#eeeeee, #d8d8d8);
  border: 1px solid #ebe9e5;
  border-bottom-color: #dfded8;
  border-right-color: #dfded8;
  box-shadow: 2px 4px 10px -8px rgb(0 0 0 / 30%);
  padding: var(--polaroid-padding);
  position: relative;
  width: calc(var(--polaroid-size) + 2 * var(--polaroid-padding));
  transform: rotate(var(--angle))
}

@media (min-width: 1024px) {
  .Polaroid--tape::after,
  .Polaroid--tape::before {
    content: '';
    display: block;
    width: 100px;
    height: 40px;
    background: rgb(200 200 200 / 40%);
    position: absolute;
  }
}

.Polaroid--tape::after {
  top: 0;
  left: 0;
  transform: rotate(-48deg) translate(-20%, -100%);
}

.Polaroid--tape::before {
  bottom: 0;
  right: 0;
  transform: rotate(-42deg) translate(20%, 100%);
}

.Polaroid--tape.Polaroid--tape--alternate::after {
  left: unset;
  right: 0;
  transform: rotate(42deg) translate(20%, -100%);
}

.Polaroid--tape.Polaroid--tape--alternate::before {
  right: unset;
  left: 0;
  transform: rotate(48deg) translate(-20%, 100%);
}

.Polaroid__image {
  height: var(--polaroid-size);
  width: var(--polaroid-size);
}

.Polaroid__image__wrapper {
  border: 2px solid #faf8f4;
  border-left: 0;
  border-top: 0;
  height: calc(var(--polaroid-size) + 2px);
  width: calc(var(--polaroid-size) + 2px);
}

.Polaroid__image__wrapper::after {
  box-shadow: inset -5px -5px 30px -20px rgb(0 0 0 / 10%);
  content: '';
  height: 100%;
  left:0;
  position: absolute;
  top:0;
  width: 100%;
}

.Polaroid__label {
  text-align: center;
  font-family: 'Reenie Beanie';
  font-size: 24px;
  line-height: 19px;
  margin-top: 30px;
  transform: rotate(-2.7deg);
}