.Note {
  align-items: center;
  display: inline-flex;
  font-size: 32px;
  height: 230px;
  justify-content: center;
  padding: 20px; 
  position: relative;
  transform: rotate(350deg);
  width: 250px;
}

.Note::after {
  background-color: var(--background);
  border-bottom-right-radius: 80px 9px;
  box-shadow: 4px 10px 18px -10px rgb(0 0 0 / 45%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.Note::before {
  border-top-right-radius: 25px 20px;
  bottom: 20px;
  box-shadow: 2px 15px 8px 2px rgb(0 0 0 / 20%);
  color: transparent;
  content: '';
  height: 20px;
  position: absolute;
  right: 10px;
  transform: rotate(20deg) skew(30deg);
  width: 80px;
  z-index: -1;
}

.Note--pink {
  --background: #fe4ca9;
}

.Note--yellow {
  --background: #ffeb3b;
}
