.Navigation__wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: var(--horizontal-padding-medium) 20px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}

.Navigation__toggle {
  align-items: center;
  background: #FFFFFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 6px 14px;
  position: relative;
  transition: background-color 100ms ease-out;
  z-index: 1;
}

.Navigation__toggle::before {
  --border-width: 2px;
  --offset: 4px;
  background: transparent;
  border-radius: 4px;
  border: var(--border-width) solid white;
  box-sizing: border-box;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition-property: width, height, top, left, border-radius;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  width: 100%;
}

.Navigation__toggle:hover::before, 
.Navigation__toggle:focus::before {
  border-radius: 6px;
  height: calc(100% + calc(2 * var(--offset) + 2 * var(--border-width)));
  left: calc(0px - (var(--offset) + var(--border-width)));
  top: calc(0px - (var(--offset) + var(--border-width)));
  transition-duration: 150ms;
  width: calc(100% + calc(2 * var(--offset) + 2 * var(--border-width)));
}

.Navigation__toggle__label {
  font-size: 16px;
  margin-right: 12px;
  transition: color 100ms ease-out;
}

.Navigation__toggle__icon {
  background: var(--graphite);
  display: block;
  font-size: 0;
  height: 4px;
  width: 24px;
  position: relative;
  opacity: 1;
  transition: background-color 600ms ease-out;
  margin: 16px 0;
}

.Navigation__toggle__icon::before,
.Navigation__toggle__icon::after {
  content: '';
  display: block;
  width: 24px;
  height: 4px;
  background: var(--graphite);
  position: absolute;
  transform: rotate(0);
  left: 0;
  transition: transform 600ms;
  transform-origin: 0 0;
}

.Navigation__toggle__icon::before {
  top: -8px;
}

.Navigation__toggle__icon::after {
  bottom: -8px;
}

.Navigation--show + .Navigation__toggle {
  background-color: transparent;
}

.Navigation--show + .Navigation__toggle::before {
  border-color: transparent;
}

.Navigation--show + .Navigation__toggle .Navigation__toggle__label {
  color: transparent;
}

.Navigation--show + .Navigation__toggle .Navigation__toggle__icon {
  background-color: transparent;
}

.Navigation--show + .Navigation__toggle .Navigation__toggle__icon::before {
  transform: rotate(45deg) translate(3px, -3px);
}

.Navigation--show + .Navigation__toggle .Navigation__toggle__icon::after {
  transform: rotate(-45deg) translate(0.5px, 2px);
}

.Navigation {
  list-style: none;
}

.Navigation__link {
  color: var(--graphite);
  display: block;
  font-family: 'Hi Melody';
  font-size: 42px;
  line-height: 36px;
  padding: 8px 30px 8px 48px;
  position: relative;
  text-decoration: none;
}

.Navigation__link__title {
  position: relative;
  z-index: 1;
}

.Navigation__link:hover {
  --rotation: calc(2.5 * var(--rotation-amount));
  transition: transform 120ms ease-out;
}

@media (max-width: 699px) {
  .Navigation {
    --background: #ffeb3b;    
    bottom: 10px;
    font-size: 32px;
    height: 230px;
    margin: 0;
    padding: 20px; 
    position: absolute;
    transform: rotate(-10deg) translateY(200%);
    transition: transform 600ms ease-out;
    width: 250px;
  }

  .Navigation--show {
    transform: rotate(-3deg);
  }
  
  .Navigation::after {
    background-color: var(--background);
    border-bottom-right-radius: 80px 9px;
    box-shadow: 4px 10px 18px -10px rgb(0 0 0 / 45%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  .Navigation::before {
    border-top-right-radius: 25px 20px;
    bottom: 20px;
    box-shadow: 2px 15px 8px 2px rgb(0 0 0 / 20%);
    color: transparent;
    content: '';
    height: 20px;
    position: absolute;
    right: 10px;
    transform: rotate(20deg) skew(30deg);
    width: 80px;
    z-index: -1;
  }

  .Navigation__link--active::before {
    background-image: url('/src/assets/sketches/big-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 38px;
    left: 0;
    position: absolute;
    top: 5px;
    width:41px;
  }
}

@media (max-width: 699px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 699px) and (min-resolution: 192dpi) {
  .Navigation__link--active::before {
    background-image: url('/src/assets/sketches/big-arrow-2x.png')
  }
}

@media (min-width: 700px) {
  .Navigation__wrapper {
    display: block;
    position: relative;
  }

  .Navigation__toggle {
    display: none;
  }

  .Navigation {
    background: linear-gradient(to bottom, rgb(50 50 50 / 75%) 0%, rgb(80 80 80 / 25%) 50%, rgb(120 120 120 / 25%) 100%);
    border-radius: 30px;
    box-shadow: inset 0px 0px 18px -3px rgb(0 0 0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  .Navigation__link {
    display: inline-block;
    font-family: 'Helvetica Neue';
    font-size: 1em;
    font-weight: 200;
    padding: 15px 30px;
    line-height: 16px;
    margin: 4px 30px 10px 10px;
    position: relative;
  }

  .Navigation__link--active {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
  }
  
  .Navigation__link::before {
    content: '';
    width: calc(100% - 10px);
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: var(--outer-edge-shape);
    padding: 0 24px 5px 0;
    background: radial-gradient(ellipse at 90% 50%, var(--color-edge-start), var(--color-edge-end));
  }
  
  .Navigation__link::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--inner-edge-shape);
    background: radial-gradient(ellipse at 100% 100%, var(--color-top-end), var(--color-top-start));
  }

  .Navigation__link--pink {
    --color-top-start: #ef93aa;
    --color-top-end: #e5859d;
    --color-edge-start: #df8499;
    --color-edge-end: #BC6A79;
    --inner-edge-shape: 10px 50px 32px 10px;
    --outer-edge-shape: 10px 80px 32px 24px;
    --rotation-amount: -1.5deg;
    --rotation: var(--rotation-amount);
    transform: rotate(var(--rotation)) translateY(2px);
  }

  .Navigation__link--yellow {
    --color-top-start: #fff389;
    --color-top-end: #fbee98;
    --color-edge-start: #fdea72;
    --color-edge-end: #ead88b;
    --inner-edge-shape: 10px 72px 50px 10px;
    --outer-edge-shape: 10px 82px 80px 24px;
    --rotation-amount: -0.5deg;
    --rotation: var(--rotation-amount);
    transform: rotate(var(--rotation))
  }

  .Navigation__link--green {
    --color-top-start: #7cf088;
    --color-top-end: #80d585;
    --color-edge-start: #69d967;
    --color-edge-end: #40c94c;
    --inner-edge-shape: 10px 14px 10px 12px;
    --outer-edge-shape: 10px 24px 10px 20px;
    --rotation-amount: 1deg;
    --rotation: var(--rotation-amount);
    transform: rotate(var(--rotation)) translateY(-1px);
  }

  .Navigation__link--orange {
    --color-top-start: #f6917f;
    --color-top-end: #f5836d;
    --color-edge-start: #e67764;
    --color-edge-end: #e16a4f;
    --inner-edge-shape: 30px 14px 20px 62px;
    --outer-edge-shape: 30px 30px 25px 70px;
    --rotation-amount: -1deg;
    --rotation: var(--rotation-amount);
    transform: rotate(var(--rotation)) translateY(2px);
  }

  .Navigation__link--purple {
    --color-top-start: #cb89ff;
    --color-top-end: #bb7aef;
    --color-edge-start: #a072c4;
    --color-edge-end: #ad70dd;
    --inner-edge-shape: 73px 60px 50px 70px;
    --outer-edge-shape: 40px 30px 30px 40px;
    --rotation-amount: -2deg;
    --rotation: var(--rotation-amount);
    transform: rotate(var(--rotation)) translateY(1px);
  }
}