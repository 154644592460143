@media (max-width: 1023px) {
  .Page__header {
    align-items: center;
    aspect-ratio: 5 / 3;
    background: linear-gradient(#ecedf4 0px, #ecedf4 41px, #e9bccd 42px, #e9bccd 43px, transparent 44px), repeating-linear-gradient(to bottom, #ecedf4 0 20px, #cfe3e4 20px 22px);
    box-shadow: 0 0 8px -3px rgb(50 50 50 / 30%);
    color: var(--graphite);
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    margin: auto;
    max-width: 600px;
    padding: 46px 23px 23px;
    transform: var(--rotation);
    z-index: 1;
  }

  .Page__header h1 {
    font-size: 2em;
    text-align: center;
  }

  .Page {
    overflow: hidden;
  }

  .Page__content {
    padding-bottom: 20px;
  }
}

@media (min-width: 614px) and (max-width: 1023px) {
  @supports not (aspect-ratio: 5 / 3) {
    .Page__header {
      height: 396px;
    }
  }
}

@media (min-width: 1024px) {
  .Page {
    aspect-ratio: 17 / 22;
    background: var(--color-paper);
    border-radius: 2px 8px 8px 2px;
    box-shadow: 0 0 8px -2px rgb(50 50 50 / 50%);
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    margin: 30px auto;
    max-width: 900px;
    position: relative;
    transform: rotate(-1deg);
    width: 80%;
  }
  
  .Page__header {
    border-left: 1px solid var(--color-line-pink);
    margin: 0 calc(var(--horizontal-padding-page) / 2) 0 var(--horizontal-padding-page);
    padding-left: var(--horizontal-padding-medium);
  }
  
  .Page__header h1 {
    margin: 1.6em 0 0;
  }

  .Page__content-wrapper {
    background: linear-gradient(var(--color-line-blue) 4.5%, transparent 0) 0 0 / auto 1.6em;
    display: flex;
    flex: 1;
    padding: 0 var(--horizontal-padding-page);
  }
  
  .Page__content {
    border-left: 1px solid var(--color-line-pink);
    flex: 1;
    line-height: 1.6em;
    padding-top: 0.8em;
  }
  
  .Page__header::before,
  .Page__content::before,
  .Page__content::after {
    background: #a97f63;
    border-radius: 50%;
    box-shadow: inset 2px 3px 5px -4px rgb(0 0 0 / 80%);
    content: '';
    display: block;
    height: 30px;
    left: 5%;
    position: absolute;
    width: 30px;
  }
  
  .Page__header::before {
    top: 10%;
  }
  
  .Page__content::before {
    top: 50%;
  }
  
  .Page__content::after {
    top: 90%;
  }
}