.Home__rsvp-link {
  position: relative;
}

.Home__rsvp-link:before {
  background: #ffff00e8;
    content: "";
    transform: skewX(-32deg);
    position: absolute;
    top: 0;
    left: -20px;
    border-radius: 4px;
    bottom: 0;
    z-index: -1;
    right: -20px;
}

.Home__doodle--date {
  width: 170px;
  position: absolute;
  bottom: 10px;
  left: 20px;
  transform: rotate(7deg);
}

@media (max-width: 1023px) {
  .Home__section .Note {
    display: flex;
    margin: auto;
    right: -130px;
    top: -40px;
    z-index: 1;
  }

  .Home__doodle--details {
    bottom: 8%;
    left: 8%;
    position: absolute;
    width: 100px;
  }

  .Home__doodle--date {
    width: 140px;
  }
}

@media (min-width: 1024px) {
  .Home__details__note {
    top: 40px;
    left: 50px;
  }

  .Home__doodle--details {
    position: absolute;
    left: 10px;
    bottom: -10px;
    width: 120px;
  }
}

@media (max-width: 539px) {
  .Home__doodle--date {
    display: none;
  }

  .Home__section .Note {
    right: -40px;
    top: -20px;
  }

  .Home__doodle--details {
    display: none;
  }
}

.Home__doodle--location {
  width: 100px;
  opacity: 0.7;
}

.Home__doodle--details-note {
  position: absolute;
  top: 15px;
  left: 15px;
  opacity: 0.7;
  width: 60px;
}

.Home__details__note {
  padding-top: 40px;
}

.Home__details__note ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Home__details__note a {
  color: var(--graphite);
}