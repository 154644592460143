.Page__section__details {
  text-align: center;
}

.Page__section__details a {
  color: var(--graphite);
}

@media (min-width: 614px) and (max-width: 1023px) {
  @supports not (aspect-ratio: 5 / 3) {
    .Page__section__details {
      height: 396px;
    }
  }
}

@media (max-width: 1023px) {
  .Page__section__details {
    align-items: center;
    aspect-ratio: 5 / 3;
    background: linear-gradient(#ecedf4 0px, #ecedf4 41px, #e9bccd 42px, #e9bccd 43px, transparent 44px), repeating-linear-gradient(to bottom, #ecedf4 0 20px, #cfe3e4 20px 22px);
    box-shadow: 0 0 8px -3px rgb(50 50 50 / 30%);
    color: var(--graphite);
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    font-size: 1.6em;
    justify-content: center;
    line-height: 1.2em;
    margin: auto;
    max-width: 600px;
    padding: 46px 23px 23px;
    position: relative;
    transform: var(--rotation);
    z-index: 1;
  }

  .Page__section__details h2 {
    margin-bottom: .6em;
  }

  .Page__section:nth-child(1n) .Page__section__details {
    --rotation: rotate(2deg);
  }
  .Page__section:nth-child(2n) .Page__section__details {
    --rotation: rotate(-1deg);
  }
  .Page__section:nth-child(3n) .Page__section__details {
    --rotation: rotate(1.5deg);
  }
  .Page__section:nth-child(4n) .Page__section__details {
    --rotation: rotate(3deg);
  }
  .Page__section:nth-child(5n) .Page__section__details {
    --rotation: rotate(-2deg);
  }
  .Page__section:nth-child(6n) .Page__section__details {
    --rotation: rotate(0deg);
  }
  .Page__section:nth-child(7n) .Page__section__details {
    --rotation: rotate(1deg);
  }
  .Page__section:nth-child(8n) .Page__section__details {
    --rotation: rotate(0.5deg);
  }

  .Page__section .Polaroid {
    margin: auto;
  }

  .Page__section .Note {
    display: flex;
    margin: auto;
    right: -62px;
    top: 0px;
    z-index: 1;
  }

  .Page__doodle--details {
    bottom: 8%;
    left: 8%;
    position: absolute;
    width: 100px;
  }

  .Page__doodle--date {
    width: 140px;
  }
}

@media (min-width: 1024px) {
  .Page__section {
    display: flex;
    justify-content: space-between;
    margin: 0.8em 0
  }

  .Page__section--reversed {
    flex-direction: row-reverse;
  }
  
  .Page__section__details {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 1.8em;
    position: relative;
  }
  
  .Page__details__note {
    top: 40px;
    left: 50px;
  }

  .Page__doodle--details {
    position: absolute;
    left: 10px;
    bottom: -10px;
    width: 120px;
  }
}