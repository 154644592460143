.Story__do-you-like-me {
  display: block;
  margin: 80px auto 0;
  width: 80%;
}

.Story__sperica-4eva {
  display: block;
  margin: 50px auto;
  width: 60%;
  opacity: 0.75;
}

.Story__three-hearts {
  position: absolute;
  left: 70%;
  top: 60%;
  transform: scale(-0.75, 0.75);
  opacity: 0.7;
}

.Story__sun,
.Story__covid {
  position: absolute;
  right: 35%;
  top: 60%;
}

.Story__engagement-heart {
  position: absolute;
  top: 23px;
  left: 175px;
  width: 20px;
  transform:scaleX(-1) rotate(-2deg);
}

@media (max-width: 639px) {
  .Story__sun,
  .Story__covid,
  .Story__three-hearts {
    position: static;
    margin-left: 10%;
    transform: none;
  }

  .Story__three-hearts {
    transform: scale(0.65);
  }
}