@media (min-width: 540px) {
  .RSVP__wrapper {
    background: #f3f4f6;
    border-radius: 10px;
    /* box-shadow: 0 0 0 3px #555555, 0 0 0 60px #232323, 0 0 0 63px #555555, 5px 5px 5px 60px #232323b5; */
    box-shadow: 0 0 0 3px #b1aeae, 0 0 0 60px #f2eded, 0 0 0 63px #b1aeae, 5px 5px 5px 60px #232323b5;
    display: flex;
    margin: 80px auto;
    min-height: 800px;
    max-width: 900px;
    overflow: hidden;
    width: 80%; 
  }
}
